<script>
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {  helpers,  required} from "@vuelidate/validators";
import myLoader from "@/components/myLoader.vue";
import { editarTipificacion,leerTipificacion } from "@/api/system";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  
  data() {
    return {
      visible:false,
      Name:null,
      tValue:null,
      tText:null,  
      tClass:null,
      Description:null,
      isLoader:false,
      submitted:null,
    }
  },
  props: {
    currentConcept : { type: Number, required: false,
      default(){
          return 0
      }
    }  
  },
  watch: {
    currentConcept(){
      if(this.currentConcept==0 || this.currentConcept==null ) return;
      this.readConcept();
    }
    // LatLng(data){
    //   this.Latitude=data.lat;
    //   this.Longitude=data.lng;
    // }
  },
  components: {
    myLoader,
   
  },
  validations: {
    Name: {
      required: helpers.withMessage("El nombre es requerido", required),
    },
    tValue: {
      required: helpers.withMessage("El valor es requerido", required),
    },
    tText: {
      required: helpers.withMessage("El text es requerido", required),
    },
    tClass: {
      required: helpers.withMessage("la clase es requerido", required),
    },
    Description: {
      required: helpers.withMessage("La descripción es requerida", required),
    },
  },  
  methods: {
    async readConcept() {
      this.isLoader=true
      let body={RowId:this.currentConcept}
      const result = await leerTipificacion(body);
      this.isLoader=false;
      if (result.data.code==200){
        this.tPartner=result.data.data[0].tPartner;
        this.module=result.data.data[0].Module;
        this.Name=result.data.data[0].Name;
        this.tValue=result.data.data[0].tValue;
        this.tText=result.data.data[0].tText;
        this.tClass=result.data.data[0].tClass;
        this.Description=result.data.data[0].Description;
      }else{  
        Swal.fire("Leyendo Lista Especial!", result.data.data[0], "error");
      }    
    },
    trySubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        // Aquí podrías realizar la lógica para guardar los datos
        this.updateConcept();
        document.getElementById("btnCloseModalConceptsEdit").click();
        this.$emit("refreshConcept");
      }
      this.isLoader = false;
    },
    async updateConcept() {
      this.isLoader=true
      let body={
        RowId:this.currentConcept,
        Module:this.module,
        Name:this.Name,
        tPartner:this.tPartner,
        tValue:this.tValue,
        tText:this.tText,
        tClass:this.tClass,
        Description:this.Description,
      }
      const result = await editarTipificacion(body);
      this.isLoader=false;
      if (result.data.code==200){
        Swal.fire("Actualizando Concepto!", "Proceso Exitoso!", "success");
      }else{  
        Swal.fire("Actualizando Concepto!", result.data.data[0], "error");
      }    
    },

  
  },  
  mounted() {
   
  }   
}  
</script>


<!-- Default Modals -->
<template>
  <div id="ModalConceptsEdit" class="modal fade" tabindex="-1" aria-labelledby="ModalConceptsEditLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg drawer-dialog-right">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title text-info" id="ModalConceptsEditLabel">Edición de Conceptos</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form id="createForm" @submit.prevent="trySubmit">
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="Name" class="form-label"
                    >Nombre</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="Name"
                    placeholder="Nombre del concepto"
                    v-model="Name"
                    :class="{
                      'is-invalid': submitted && v$.Name.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.Name.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="tValue" class="form-label"
                    >Valor</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tValue"
                    placeholder="Valor de Concepto"
                    v-model="tValue"
                    :class="{
                      'is-invalid': submitted && v$.tValue.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.tValue.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="tText" class="form-label"
                    >Texto</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tText"
                    placeholder="Texto de Concepto"
                    v-model="tText"
                    :class="{
                      'is-invalid': submitted && v$.tText.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.tText.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="tClass" class="form-label"
                    >Clase</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tClass"
                    placeholder="Clase del Concepto"
                    v-model="tClass"
                    :class="{
                      'is-invalid': submitted && v$.tClass.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.tClass.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="Name" class="form-label"
                    >Descripcion</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="Name"
                    placeholder="Descripcion de la lista"
                    v-model="Description"
                    :class="{
                      'is-invalid': submitted && v$.Description.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.Description.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
             </div>
          </form>  
        </div>
        <div class="modal-footer">
          <button type="button" id="btnCloseModalConceptsEdit" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="trySubmit">Grabar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
</template>


