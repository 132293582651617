<script>
 
  import tableBasic from "@/components/tables/tableBasic.vue";

  import myLoader from "@/components/myLoader.vue";

  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../../app.config";

  import Create from './create.vue';
  import Edit   from './edit.vue';
  import CreateConcept from './concepts/create.vue';
  import EditConcept from './concepts/edit.vue';

  import { leerTipificationes, 
           borrarTipificationes,
           leerTipificationesConcepto,
         } from "@/api/typifications";
  import Swal from "sweetalert2";
  

  export default {
    page: {
      title: "Listas Especiales",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    components: {
      Layout,
      PageHeader,
      tableBasic,
      myLoader,
      Create,
      Edit,
      CreateConcept,
      EditConcept,
    },
    data() {
      return {
        title: "Listas Especiales",
        items: [{
            text: "CONFIGURACIÓN",
            href: "/Mod_NS_Configuration/ns_Typification",
          },
          {
            text: "Listas Especiales",
            active: true,
          },
        ],
        columns: [
          {
            title: "Id",
          },{
            title: "Módulo",
          },{
            title: "Nombre",
          }
        ],
        currentPage: 1,
        pageSize: 8,
        qLink:5,
        dataTypifications:null,
        linkTypifications:null,
        currentPartner:1,
        currentPartnerName:null,
        currentConcept:null,
        switchDeleteConcept:0,  //0 - Borra lista 1 - Borra Concepto

        columns2: [
          {
            title: "Id",
          },{
            title: "Nombre",
          },{
            title: "Valor",
          },{
            title: "Texto",
          },{
            title: "Clase",
          },{
            title: "Descripción",
          }
        ],
        dataTypificationsConcept:null,
        linkTypificationsConcept:null,
      } 
    },
  
    watch: {
      // contactsList() {
      //   this.setPages();
      // },
    },
    // created() {
    //   this.setPages();
    // },
    
    methods: {
     
      keyPartner(key){
        this.currentPartner=key;
        var result=this.dataTypifications.filter(obj=> obj.RowId == key);
        this.currentPartnerName=result[0].Name;
        this.get_typificationsConcept();
      },
      deletePartner(key){
        this.keyPartner(key);
        Swal.fire({
          title: 'Desea Borrar la lista:'+this.currentPartnerName+'?',
          showDenyButton: true,
          confirmButtonText: 'Borrar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.switchDeleteConcept=0;
            this.delete_typifications() 
            Swal.fire('Borrada!', '', 'success')
          } else if (result.isDenied) {
            Swal.fire('Lista no borrada', '', 'info')
          }
        })
      },
      updatePartner(key){
        this.currentPartner=key;
        document.getElementById("btn-edit-partner").click();
      },
      updateConcept(key){
        this.currentConcept=key;
        document.getElementById("btn-edit-concept").click();
      },
      deleteConcept(key){
        this.currentConcept=key;
        var result=this.dataTypificationsConcept.filter(obj=> obj.RowId == key);
        Swal.fire({
          title: 'Desea Borrar el concepto:'+result[0].Name+'?',
          showDenyButton: true,
          confirmButtonText: 'Borrar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.switchDeleteConcept=1
            this.delete_typifications() 
            Swal.fire('Concepto borrado!', '', 'success')
          } else if (result.isDenied) {
            Swal.fire('Concepto no borrado', '', 'info')
          }
        })
      },
      pageChange(page){
        this.currentPage=page;
        this.get_typifications();
      },
      async delete_typifications() {
        this.isLoader=true
        const result = await borrarTipificationes({
           RowId:this.switchDeleteConcept==0?this.currentPartner:this.currentConcept,
        });
        if (result.data.code==200){
          this.get_typifications();
          return true;
        }
        return false;  
      }, 
      async get_typifications() {
        this.isLoader=true
        var filter = document.getElementById("filtroNombre").value;
        let filterSearch='XXNNXX';
        if(filter.length>2){
          filterSearch=[];
          filterSearch.push({'campo':'Name','operador':'like','valor':'%'+filter+'%'});
        }
        const result = await leerTipificationes({
          filter: filterSearch,
          page:this.currentPage,
          pageSize:this.pageSize,
        });
        this.dataTypifications = [];
        result.data.data.data.map((item) => {
          this.dataTypifications.push({
            RowId:item.RowId,
            Module:item.modulo.Description, 
            Name:item.Name,
            tValue:item.tValue,
            tText:item.tText,
            tClass:item.tClass,
            Description:item.Description
          });
        });
        this.currentPartner=this.dataTypifications[0].RowId;
        this.currentPartnerName=this.dataTypifications[0].Name;
        this.linkTypifications = result.data.data.links;
        this.get_typificationsConcept();
        this.isLoader=false;
      }, 
      async get_typificationsConcept() {
        this.isLoader=true
        var filter = document.getElementById("filtroConcepto").value;
        let filterSearch='XXNNXX';
        if(filter.length>2){
          filterSearch=[];
          filterSearch.push({'campo':'Name','operador':'like','valor':'%'+filter+'%'});
        }
        const result = await leerTipificationesConcepto({
          tPartner:this.currentPartner,
          filter: filterSearch,
          page:this.currentPage,
          pageSize:this.pageSize,
        });
        this.dataTypificationsConcept = [];
        this.linkTypificationsConcept = result.data.data.links;
        result.data.data.data.map((item) => {
          this.dataTypificationsConcept.push({
            RowId:item.RowId,
            // Module:item.modulo.Description, 
            Name:item.Name,
            tValue:item.tValue,
            tText:item.tText,
            tClass:item.tClass,
            Description:item.Description
          });
        });
       
        this.isLoader=false;
      }, 
    },  
    mounted() {
      // toma datos de tipificaciones
      this.get_typifications();
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-4">
        <div class="card" id="PartnetList">
          <div class="card-header">
            <div class="row g-3">
              <div class="col-md-4">
                <div class="search-box">
                  <input type="text"  id="filtroNombre"
                  @keyup="get_typifications"
                  class="form-control search" placeholder="Buscar lista..." />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-8">
                <div class="flex-grow-1">
                  <div class="text-end"> 
                      <!-- Button trigger modal crear rutas -->
                      <button type="button" class="btn btn-info add-btn me-1"
                        data-bs-toggle="modal"
                        data-bs-target="#ModalTipificationsCreate">
                        <i class="ri-add-fill me-1 align-bottom"></i> Adicionar Lista
                      </button> 
                      <button v-show="currentPartner==-1" type="button" class="btn btn-info add-btn me-1"
                        id="btn-edit-partner"
                        data-bs-toggle="modal"
                        data-bs-target="#ModalTipificationsEdit">
                      </button> 
                  </div> 
                </div> 
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                   <!-- filtro -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <tableBasic
              :columns="columns"
              :data="dataTypifications"
              :currentPage="currentPage"
              :pageSize="pageSize"
              :links="linkTypifications"
              buttonKey="1"
              itemKey="0"
              @pageChange="pageChange"
              @updateKey="updatePartner" 
              @key="keyPartner" 
              @deleteKey="deletePartner"
              />
            </div>

          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-8">
        <div class="card" id="ConceptList">
          <div class="card-header">
            <div class="row g-3">
              <div class="col-md-4">
                <div class="search-box">
                  <input type="text"  id="filtroConcepto"
                  @keyup="get_typifications"
                  class="form-control search" placeholder="Buscar Concepto..." />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-4">
                <h4 class="mt-2 font-weight-bold text-center text-danger">{{ currentPartnerName }}</h4>
              </div>  
              <div class="col-md-4">
                <div class="flex-grow-1">
                  <div class="text-end"> 
                    <button type="button" class="btn btn-info add-btn me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalConceptsCreate">
                      <i class="ri-add-fill me-1 align-bottom"></i> Adicionar Concepto
                    </button>
                    <button v-show="currentPartner==-1" type="button" class="btn btn-info add-btn me-1"
                    id="btn-edit-concept"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalConceptsEdit">
                  </button>  
                  </div> 
                </div> 
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                  <!-- filtro -->
                </div>
              </div>
            </div>
          </div>  
          <div class="card-body">
            <div>
              <tableBasic
              :columns="columns2"
              :data="dataTypificationsConcept"
              :currentPage="currentPage"
              :pageSize="pageSize"
              :links="linkTypificationsConcept"
              buttonKey="0"
              itemKey="0"
              @pageChange="pageChange"
              @updateKey="updateConcept" 
              @deleteKey="deleteConcept"
              />
            </div>
            
          </div>
        </div>
        <!--end card-->
      </div>
    </div>
    <!--end row-->
   
    <!-- Myloader -->
    <div v-if="isLoader" style="position:absolute; top: 35%; 
        left:20%;
        z-index: 9999;  "  >
          <myLoader /> 
    </div>
    <Create  
      @refresh="get_typifications"
    />
    <Edit
      :currentPartner="currentPartner"
      @refresh="get_typifications"
    />
    <CreateConcept
      :currentPartner="currentPartner"
      @refreshConcept="get_typificationsConcept"
    />
    <EditConcept
      :currentConcept="currentConcept"
      @refreshConcept="get_typificationsConcept"
    />
  </Layout>
</template>