<template>
  <div class="table-responsive table-card mb-3">
    <table class="table align-middle table-nowrap mb-0" id="customerTable">
      <thead class="table-light">
        <tr>
          <th v-for="(column, index) in columns" :key="index">{{ column.title }}</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td v-for="(column, colIndex) in columns" :key="colIndex">
            <span>{{ item[Object.keys(item)[colIndex]] }}</span>
          </td>
          <td>
            <ul class="list-inline mb-0">
              <li v-if="buttonEdit == 1" class="list-inline-item avatar-xs">
                <a
                  href="javascript:void(0);"
                  @click="emitUpdate(item[Object.keys(item)[itemKey]])"
                  class="avatar-title bg-soft-success text-success fs-15 rounded"
                >
                  <i class="ri-pencil-line"></i>
                </a>
              </li>
              <li v-if="buttonDelete == 1" class="list-inline-item avatar-xs">
                <a
                  href="javascript:void(0);"
                  @click="emitDelete(item[Object.keys(item)[itemKey]])"
                  class="avatar-title bg-soft-danger text-danger fs-15 rounded"
                >
                  <i class="ri-delete-bin-5-line"></i>
                </a>
              </li>
              <li v-if="buttonKey == 1" class="list-inline-item avatar-xs">
                <a
                  href="javascript:void(0);"
                  @click="emitKey(item[Object.keys(item)[itemKey]])"
                  class="avatar-title bg-soft-info text-info fs-15 rounded"
                >
                  <i class="ri-settings-2-line"></i>
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-end mt-3 px-3">
    <div class="pagination-wrap hstack gap-2">
      <!-- <a 
        class="page-item pagination-prev disabled" href="#" >
          Previous 
      </a> -->
      <ul class="pagination listjs-pagination mb-0">
        <li
          :class="pages.active == true ? 'active' : 'disabled'"
          v-for="pages in links"
          :key="pages.label"
        >
          <a
            v-if="pages.url != null"
            class="page"
            href="javascript:void(0)"
            v-html="pages.label"
            @click="changePage({ label: pages.label })"
          ></a>
        </li>
      </ul>
      <!-- <a class="page-item pagination-next" href="#" >
        Next
      </a> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    data: Array,
    currentPage: Number,
    pageSize: Number,
    links: Object,
    buttonKey: {
      type: Number,
      required: false,
      default() {
        return 1;
      },
    },
    buttonEdit: {
      type: Number,
      required: false,
      default() {
        return 1;
      },
    },
    buttonDelete: {
      type: Number,
      required: false,
      default() {
        return 0;
      },
    },
    itemKey: {
      type: Number,
      required: false,
      default() {
        return null;
      },
    },
  },
  computed: {},
  methods: {
    emitUpdate(index) {
      this.$emit("updateKey", index);
    },
    emitDelete(index) {
      this.$emit("deleteKey", index);
    },
    emitKey(index) {
      this.$emit("key", index);
    },
    changePage(pagex) {
      let page = parseInt(pagex.label);

      if (pagex.label == "...") return;
      if (pagex.label.includes("Anterior")) {
        page = this.currentPage - 1;
      }
      if (pagex.label.includes("Siguiente")) {
        page = this.currentPage + 1;
      }

      this.$emit("pageChange", page);
    },
  },
};
</script>
