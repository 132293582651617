<script>
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {  helpers,  required} from "@vuelidate/validators";
import myLoader from "@/components/myLoader.vue";
import { crearTipificacion } from "@/api/system";
import { listarModulosBasico } from "@/api/system";

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  
  data() {
    return {
      visible:false,
      module:null,
      Description:null,
      dataModule:[],
      Name:null,
      isLoader:false,
      submitted:null,
    }
  },
  props: {
    // PointsNum: { type: String, required: false,
    //   default(){
    //       return 'P1'
    //   }
   
  },
  watch: {
    // PointsNum(data){
    //   if(data=='' || data==null ) return;
    //   this.PointsNumId=data;
    // },
    // LatLng(data){
    //   this.Latitude=data.lat;
    //   this.Longitude=data.lng;
    // }
  },
  components: {
    myLoader,
    Multiselect,
  },
  validations: {
    module: {
      required: helpers.withMessage("El Módulo es requerido", required),
    },
    Name: {
      required: helpers.withMessage("El nombre es requerido", required),
    },
    Description: {
      required: helpers.withMessage("La descripción es requerida", required),
    },
  },  
  methods: {
    trySubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        // Aquí podrías realizar la lógica para guardar los datos
        this.save();
        document.getElementById("btnCloseModalCreate").click();
        this.$emit("refresh");
      }
      this.isLoader = false;
    },
    async save() {
      this.isLoader=true
      let body={
        Module:this.module,
        Name:this.Name,
        tPartner:null,
        tValue:null,
        tText:null,
        tClass:null,
        Description:this.Description,
      }
      const result = await crearTipificacion(body);
      this.isLoader=false;
      if (result.data.code==200){
        Swal.fire("Creando Lista Especial!", "Proceso Exitoso!", "success");
        
      }else{  
        Swal.fire("Creando Lista Especial!", result.data.data[0], "error");
      }    
    },

    async loadModules() {
      this.isLoader=true
      const result2 = await listarModulosBasico();
      this.isLoader=false;
      if (result2.result.data.code==200){
        this.dataModule=[];
        result2.result.data.data.map((item) => {
          this.dataModule.push({
            value: item.MODULEID, 
            label: item.Description
          });  
        });
        this.module=this.dataModule[0].value;
      }else{  
        alert("error en seleccion de modulos");
      }    
    },
  },  
  mounted() {
    this.loadModules();
  }   
}  
</script>


<!-- Default Modals -->
<template>
  <div id="ModalTipificationsCreate" class="modal fade" tabindex="-1" aria-labelledby="ModalTipificationsCreateLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg drawer-dialog-right">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title text-info" id="ModalTipificationsCreateLabel">Creación de Listas Especiales</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form id="createForm" @submit.prevent="trySubmit">
            <div class="row">
              <div class="col-md-6">
                <label class="form-label">Módulo</label><br>
                <div class="mt-4 mt-lg-0">
                  <Multiselect class="form-control"  v-model="module" :close-on-select="true"
                  id="module"
                  :searchable="true" 
                  :options="dataModule" />
                </div>
                <div
                  v-for="(item, index) in v$.module.$errors"
                    :key="index"
                    class="text-danger fs-7"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="Name" class="form-label"
                    >Nombre</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="Name"
                    placeholder="Nombre de la lista"
                    v-model="Name"
                    :class="{
                      'is-invalid': submitted && v$.Name.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.Name.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
             </div>
             <div class="row mt-3">
              <div class="col-md-12">
                <div class="mb-2">
                  <label for="Name" class="form-label"
                    >Descripción</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="Name"
                    placeholder="Descripcion de la lista"
                    v-model="Description"
                    :class="{
                      'is-invalid': submitted && v$.Description.$error,
                    }"
                  />
                  <div
                    v-for="(item, index) in v$.Description.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
             </div>
          </form>  
        </div>
        <div class="modal-footer">
          <button type="button" id="btnCloseModalCreate" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="trySubmit">Grabar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
</template>


