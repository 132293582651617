import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion Leer src/api/routers.js
const leerTipificationes = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/listTypificationsPartner`, 
    body,{ headers: authHeader()}
    );
    return  result ;
  } catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const leerTipificationesConcepto = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/listTypificationsConcept`, 
    body,{ headers: authHeader()}
    );
    return  result ;
  } catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const borrarTipificationes = async (body) => {
  try {                                                                     
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/typificationsDelete`, 
      body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};



export { leerTipificationes, 
  borrarTipificationes, leerTipificationesConcepto};
